import { Badge } from '@mui/material';
import { memo } from 'react';

import { AddIcon, CloseIcon, FilterIcon } from '../../../Icons';
import { useTranslator } from '../../../contexts/TranslationContext';
import { useTheme } from '../../../theme';
import { getColor } from '../../Button/IconButton/helpers';
import { ActionButton } from '../ActionButton/ActionButton';

import { TITLE_DEFAULT_SIZE, TITLE_SIZES } from './TableActions.constants';
import {
  Description,
  Header,
  HeaderActions,
  HeaderTitle,
} from './TableActions.styled';
import { TableActionsProps, TTags } from './TableActions.types';

export const TableActionsComponent = ({
  title,
  description,
  hasFilters,
  onToggleFilterExpanded,
  filtersExpanded,
  hasActiveFilters,
  exportButton,
  onAdd,
  onClose,
  additionalActions,
  activeFiltersCount,
  titleSize = TITLE_DEFAULT_SIZE,
}: TableActionsProps) => {
  const { t } = useTranslator();
  const theme = useTheme();
  const color = getColor(theme, 'action');

  const Tag: TTags = TITLE_SIZES.includes(titleSize)
    ? `h${titleSize}`
    : `h${TITLE_DEFAULT_SIZE}`;
  const titleTag = <Tag className="HeaderTitle">{title}</Tag>;

  return (
    <Header>
      {title && (
        <HeaderTitle>
          {titleTag}
          {description && <Description>{description}</Description>}
        </HeaderTitle>
      )}
      <HeaderActions>
        {additionalActions?.map((actionButtonProps) => (
          <ActionButton key={actionButtonProps.title} {...actionButtonProps} />
        ))}
        {exportButton}
        {hasFilters && (
          <ActionButton
            onClick={onToggleFilterExpanded}
            active={filtersExpanded}
            title={t('ui__table__actions__set_filters')}
            iconColor={hasActiveFilters ? 'action' : undefined}
          >
            <Badge
              badgeContent={activeFiltersCount}
              sx={{
                '& .MuiBadge-badge': {
                  top: 5,
                  right: 5,
                },
              }}
            >
              <FilterIcon size={24} />
            </Badge>
          </ActionButton>
        )}
        {onAdd && (
          <ActionButton
            onClick={onAdd}
            title={t('ui__table__actions__add')}
            iconColor="action"
          >
            <AddIcon applyStates={false} size={24} color={color} />
          </ActionButton>
        )}
        {onClose && (
          <ActionButton
            onClick={onClose}
            title={t('ui__table__actions__close')}
          >
            <CloseIcon size={24} />
          </ActionButton>
        )}
      </HeaderActions>
    </Header>
  );
};

export const TableActions = memo(TableActionsComponent);
