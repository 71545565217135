import { PATH_DELIMITER } from '~/constants';
import { SpecialNodes } from '~/constants/limits';
import { LimitsState } from '~/types/limits';
import { FilterLayers, TreeStructure } from '~/types/treeStructure';

import { getPostLimitsEndpoint } from '../endpoints';
import { prepareLayerInfo, prepareMode } from '../helpers';

import { limitsApi } from './limitsApi';

interface Store {
  limits: LimitsState;
  treeStructure: TreeStructure;
}

interface ResultInstrument {
  manual: boolean | null;
  mode: string;
  negativeLim: number | null;
  positiveLim: number | null;
  setId?: number | null;
  optionGroupId?: string;
  currency?: string;
  symbolId?: string;
}

interface ResultNode {
  manual: boolean | null;
  mode: string;
  negativeLim: number | null;
  positiveLim: number | null;
  setId?: number | null;
  currency?: string;
  path?: string;
}

export const { useSaveLimitsMutation } = limitsApi.injectEndpoints({
  endpoints: (builder) => ({
    saveLimits: builder.mutation({
      queryFn: async (params, api, __, fetchWithBaseQuery) => {
        const state = api.getState() as Store;

        const { nodes: changedNodes, instruments: changedInstruments } =
          state.limits.changedLimits;

        const treeFilters = state.treeStructure;

        const url = getPostLimitsEndpoint(prepareLayerInfo(treeFilters));

        const postInstruments = Object.values(changedInstruments).map(
          (item) => {
            const { mode, negativeLim, positiveLim, id, override, path } = item;
            const [nodeName] = path.split(PATH_DELIMITER);

            const result: ResultInstrument = {
              manual: typeof override === 'boolean' ? override : true,
              mode: prepareMode(mode),
              negativeLim,
              positiveLim,
            };

            if (treeFilters.layer === FilterLayers.Default) {
              result.setId = treeFilters.group.defaultGroupId;
            }
            if (treeFilters.layer === FilterLayers.Groups) {
              result.setId = treeFilters.group.groupId;
            }

            if (nodeName === SpecialNodes.OPTION) {
              result.optionGroupId = id;
              return result;
            }

            if (nodeName === SpecialNodes.CURRENCY) {
              result.currency = id;
              return result;
            }

            result.symbolId = id;

            return result;
          },
        );

        const postNodes = Object.values(changedNodes).map((item) => {
          const { mode, negativeLim, positiveLim, id, override, path } = item;
          const [nodeName] = path.split(PATH_DELIMITER);

          const result: ResultNode = {
            manual: typeof override === 'boolean' ? override : true,
            mode: prepareMode(mode),
            negativeLim,
            positiveLim,
          };

          if (treeFilters.layer === FilterLayers.Default) {
            result.setId = treeFilters.group.defaultGroupId;
          }
          if (treeFilters.layer === FilterLayers.Groups) {
            result.setId = treeFilters.group.groupId;
          }

          if (nodeName === SpecialNodes.CURRENCY) {
            result.currency = id;
            return result;
          }

          result.path = path;

          return result;
        });

        const { error: errorNodes = undefined } = postNodes.length
          ? await fetchWithBaseQuery({
              url,
              method: 'POST',
              data: postNodes,
            })
          : {};

        const { error: errorInstruments = undefined } = postInstruments.length
          ? await fetchWithBaseQuery({
              url,
              method: 'POST',
              data: postInstruments,
            })
          : {};

        if (errorNodes || errorInstruments) {
          return { error: errorNodes || errorInstruments };
        }

        return { data: true };
      },
    }),
  }),
});
