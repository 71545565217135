import { Select, IconButton, styled } from 'react-ui-kit-exante';

export const WrapperStyled = styled('div')(() => ({
  display: 'flex',
  gap: '24px',
  padding: '24px 0',

  button: {
    whiteSpace: 'nowrap',
  },
}));

export const SelectStyled = styled(Select)(() => ({
  maxWidth: '280px',
  width: '280px',
}));

export const IconDeleteStyled = styled(IconButton)(() => ({
  marginRight: 'auto',
}));
