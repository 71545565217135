import { MAX_REQUEST_LIMIT } from '~/constants';
import { IPaginationResponse } from '~/types/api';
import { RowType } from '~/types/common';
import { FilterLayers } from '~/types/treeStructure';

import { getFetchLimitsInstruments } from '../endpoints';

import { limitsApi } from './limitsApi';

interface Params {
  path: string;
  limit?: number;
  skip?: number;
  layer: FilterLayers;
  entity?: string | number | null;
}

export interface ResponseLimitsInstrument {
  displayName: string;
  id: string;
  limit: {
    mode: string;
    negativeLim: string;
    positiveLim: string;
  };
  path: string;
}

interface ReturnLimitsInstruments {
  pagination: IPaginationResponse;
  path: string;
  instruments: Array<{
    displayName: string;
    id: string;
    mode: string;
    negativeLim: string;
    positiveLim: string;
    path: string;
    rowType: RowType;
  }>;
}

export const { useLazyGetLimitsInstrumentsQuery } = limitsApi.injectEndpoints({
  endpoints: (builder) => ({
    getLimitsInstruments: builder.query<ReturnLimitsInstruments, Params>({
      queryFn: async (
        { path, limit = MAX_REQUEST_LIMIT, skip = 0, layer, entity = '' },
        _,
        __,
        fetchWithBaseQuery,
      ) => {
        const { data, error } = await fetchWithBaseQuery({
          url: getFetchLimitsInstruments({ layer, entity }),
          params: { path, limit, skip },
        });

        if (error || !data) {
          return {
            data: { pagination: { total: 0 }, instruments: [], path: '' },
          };
        }

        const instruments: ResponseLimitsInstrument[] = data.data;

        return {
          data: {
            instruments: instruments.map(
              ({ limit: instrumentLimit, ...rest }) => ({
                ...instrumentLimit,
                ...rest,
                rowType: RowType.Instrument,
              }),
            ),
            pagination: data.pagination,
            path,
          },
        };
      },
      providesTags: ['LimitsInstruments'],
    }),
  }),
});
