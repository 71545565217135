import { LimitsState } from '~/types/limits';

export const INITIAL_STATE: LimitsState = {
  changedLimits: {
    nodes: {},
    instruments: {},
  },
  tree: {
    initial: [],
    standardMode: [],
    searchMode: [],
  },
  pathByPositionInTree: {
    initial: {},
    standardMode: {},
    searchMode: {},
  },
  expandedRows: {},
  downloadedPaths: [],
  refreshMode: {
    isEnabled: false,
    tree: [],
  },
};
