import { yupResolver } from '@hookform/resolvers/yup';
import { omitBy, isUndefined } from 'lodash';
import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Notification } from 'react-ui-kit-exante';

import {
  useAddLegalEntityMutation,
  useGetLegalEntitiesQuery,
  useLazyGetLegalEntityQuery,
  useUpdateLegalEntityMutation,
} from '~/api';
import { LEGAL_ENTITY_UPDATE_PATH } from '~/routes';
import { flattenOptionValues } from '~/shared/utils';
import { ILegalEntityFormData } from '~/types/legalEntities';

import { DEFAULT_VALUES } from '../constants';
import { FormMode } from '../types';
import { getValidationSchema } from '../validationSchema';

export function useLegalEntityForm(
  name: string | undefined,
  preCall: () => void,
) {
  const navigate = useNavigate();
  const [getLegalEntity, { isLoading, isFetching }] =
    useLazyGetLegalEntityQuery();
  const [addLegalEntityMutation] = useAddLegalEntityMutation();
  const [updateLegalEntityMutation] = useUpdateLegalEntityMutation();

  const { legalEntityNames } = useGetLegalEntitiesQuery(undefined, {
    selectFromResult: ({ data = [] }) => ({
      legalEntityNames: new Set(data.map((legalEntity) => legalEntity.name)),
    }),
  });

  const formInstance = useForm<ILegalEntityFormData>({
    defaultValues: DEFAULT_VALUES,
    resolver: yupResolver(
      getValidationSchema(
        name ? FormMode.Edit : FormMode.Add,
        legalEntityNames,
      ),
    ),
  });

  const {
    formState: { isDirty, isSubmitting },
    getValues,
    reset,
  } = formInstance;

  const onSubmitHandler = useCallback(
    async (data: ILegalEntityFormData) => {
      if (name) {
        const result = await updateLegalEntityMutation({
          legalEntityName: name,
          data: flattenOptionValues(data),
        });

        if ('data' in result) {
          Notification.success({
            title: 'Legal entity has been updated',
          });

          reset(omitBy(getValues(), isUndefined));
        }
      } else {
        const result = await addLegalEntityMutation(
          flattenOptionValues<ILegalEntityFormData>(data),
        );

        if ('data' in result) {
          Notification.success({
            title: 'Legal entity has been added',
          });

          reset(omitBy(getValues(), isUndefined));

          navigate(
            LEGAL_ENTITY_UPDATE_PATH.replace(':name', String(data.name)),
            {
              state: { previousPath: window.location.href },
            },
          );
        }
      }
    },
    [
      addLegalEntityMutation,
      getValues,
      name,
      navigate,
      reset,
      updateLegalEntityMutation,
    ],
  );

  const submitHandle = formInstance.handleSubmit(onSubmitHandler);

  const getLegalEntityDetails = useCallback(async () => {
    if (name) {
      preCall();

      const response = await getLegalEntity(name);

      if ('data' in response) {
        reset(response.data);
      }
    }
  }, [getLegalEntity, preCall, reset, name]);

  useEffect(() => {
    getLegalEntityDetails();
  }, []);

  return {
    formInstance,
    isDirty,
    isLoading: isLoading || isFetching,
    isSubmitting,
    refreshHandle: getLegalEntityDetails,
    submitHandle,
  };
}
