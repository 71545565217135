import { createApi } from '@reduxjs/toolkit/query/react';

import { baseQueryHandler } from '~/shared/utils';

export const limitsApi = createApi({
  reducerPath: 'limitsApi',
  baseQuery: baseQueryHandler,
  tagTypes: [
    'LimitGroups',
    'LimitsInstruments',
    'LimitsSearchInstruments',
    'LimitsTree',
  ],
  endpoints: () => ({}),
});
