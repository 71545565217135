import { uniqBy } from 'lodash';
import {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { CloseIcon } from '../../../Icons';
import { useTranslator } from '../../../contexts/TranslationContext';
import {
  TAdditionalFilter,
  TExtendedColumnWithAdditionalFields,
} from '../types';

import {
  FilterAutocompleteStyled,
  FilterChipStyled,
  FilterClearButtonStyled,
  FilterCountStyled,
  FilterHeaderLeftStyled,
  FilterHeaderRightStyled,
  FilterHeaderRowLeftStyled,
  FilterHeaderRowRightStyled,
  FilterHeaderRowStyled,
  FilterRowContainerStyled,
} from './Filters.styled';
import { FilterRow } from './components/FilterRow';
import { autocompleteValue } from './const';
import {
  getExistingFilters,
  getPredefinedFilters,
  makeFiltersData,
} from './helpers';
import { ISelectedFiltersState } from './innerTypes';
import {
  FilterValue,
  TRemoveAllFilters,
  TSetAllFilters,
  TSetFilter,
} from './types';

interface IFilterProps<Data extends object> {
  additionalFilters?: TAdditionalFilter<Data>[];
  allColumns: TExtendedColumnWithAdditionalFields<Data>[];
  data: Data[];
  defaultFiltersExpanded?: boolean;
  filters: Record<string, unknown>;
  filtersList: () => string[];
  filtersRightPanelComponent?: ReactNode;
  hasNegativeFilters?: boolean;
  isExpanded: boolean;
  negative: Set<string>;
  onChangeExpanded: (value: boolean) => void;
  removeServerFilters?: TRemoveAllFilters;
  requiredFilters?: string[];
  setAllFilters: TSetAllFilters<Data>;
  setFilter: TSetFilter<Data>;
  setNegative: (key: string, value: boolean) => void;
  useBrandedDatePicker?: boolean;
  canEditDateInput?: boolean;
}

const reservedKeys = ['viewMode', 'args', 'bookmark'];

export const Filters = <Data extends object>({
  additionalFilters,
  allColumns,
  data,
  defaultFiltersExpanded,
  filters,
  filtersList,
  filtersRightPanelComponent,
  hasNegativeFilters = false,
  isExpanded,
  negative,
  onChangeExpanded,
  removeServerFilters,
  requiredFilters,
  setAllFilters,
  setFilter,
  setNegative,
  useBrandedDatePicker,
  canEditDateInput,
}: IFilterProps<Data>) => {
  const { t } = useTranslator();

  const { base, additional } = makeFiltersData(
    allColumns,
    data,
    additionalFilters,
  );

  const predefinedFilters = useMemo(
    () => getPredefinedFilters({ filters, base, additional }),
    [additional, base, filters],
  );

  const [selectedFilters, setSelectedFilters] = useState<
    ISelectedFiltersState[]
  >(filters ? predefinedFilters : []);

  useEffect(() => {
    const filterNames: ISelectedFiltersState[] = Object.keys(filters)
      .filter((key) => !reservedKeys.includes(key))
      .map((name) => {
        const filterInAdditionalList = !!additionalFilters?.find((item) => {
          return item.accessor === name;
        });
        const type = filterInAdditionalList ? 'additional' : 'base';
        return {
          id: name,
          value: name,
          filterValue: String(filters?.[name]),
          type,
        };
      });

    setSelectedFilters(filterNames);
  }, [filters, setSelectedFilters]);

  useEffect(() => {
    const selectedFiltersCount = selectedFilters?.length || 0;

    const allFiltersCount =
      filters &&
      Object.keys(filters).filter((key) => !reservedKeys.includes(key)).length;

    const countsIsDifferent = allFiltersCount > selectedFiltersCount;

    /**
     * https://jira.exante.eu/browse/RUI-657
     * All empty filters are removed when one filter is removed or filled
     */
    if (countsIsDifferent) {
      setSelectedFilters(predefinedFilters);
    } else {
      setSelectedFilters(selectedFilters);
    }
  }, [selectedFilters, predefinedFilters]);

  const filtersOptions = useMemo(
    () => uniqBy(base.options.concat(additional.options), 'id'),
    [additional, base],
  );

  useEffect(() => {
    if (selectedFilters.length && defaultFiltersExpanded === undefined) {
      onChangeExpanded(true);
    }
  }, [selectedFilters.length, onChangeExpanded, defaultFiltersExpanded]);

  // Update only fields which there are in columns
  const handleReactTableFilterChange = useCallback(
    (id: string, value: FilterValue) => {
      if (additional.entities[id]) {
        return;
      }

      setFilter(id, value);
    },
    [additional, setFilter],
  );

  const handleClearFilter = useCallback(
    (filterId: string) => {
      const newFilters = selectedFilters.filter((el) => el.value !== filterId);

      setSelectedFilters(newFilters);
    },
    [selectedFilters],
  );

  const handleAvailableFiltersChange = useCallback(
    (event: SyntheticEvent, newValue: ISelectedFiltersState[]) => {
      setSelectedFilters((prev) => {
        // Because of ColumnSelect has empty value all time.
        // We should check duplicates
        const keys = prev.map((i) => i.value);
        if (keys.includes(newValue[0].value)) {
          return prev;
        }
        return prev.concat(newValue);
      });
    },
    [],
  );

  const handleClearAllFilters = useCallback(() => {
    if (requiredFilters && requiredFilters.length > 0) {
      const allFilters = filtersList();
      allFilters.forEach((filterName) => {
        if (!requiredFilters.includes(filterName)) {
          handleClearFilter(filterName);
        }
      });
      return;
    }
    setAllFilters([]);
    setSelectedFilters([]);

    if (removeServerFilters) {
      removeServerFilters(filtersList());
    }

    if (hasNegativeFilters) {
      selectedFilters.forEach((selectedFilter) => {
        setNegative(String(selectedFilter.value), false);
      });
    }
  }, [removeServerFilters, setAllFilters]);

  useEffect(() => {
    if (predefinedFilters.length) {
      setAllFilters(getExistingFilters(predefinedFilters));
    }
    // Set filters into inner react-table state only when component initialize (for not controlled table)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const hasSelectedFilters = Boolean(selectedFilters.length);

  return (
    <FilterRowContainerStyled
      className="FilterRowContainer"
      data-test-id="filters__row-container"
    >
      {isExpanded && (
        <>
          <FilterHeaderRowStyled className="FilterHeaderRow">
            <FilterHeaderRowLeftStyled className="FilterHeaderRowLeft">
              <FilterHeaderLeftStyled className="FilterHeaderLeft">
                <FilterCountStyled className="FilterCount">
                  {t('ui__table__filter__title')}
                  <FilterChipStyled
                    label={selectedFilters.length}
                    className="FilterChip"
                    data-test-id="filters__filter-count"
                  />
                </FilterCountStyled>
                <>
                  <FilterAutocompleteStyled<
                    ISelectedFiltersState,
                    true,
                    true,
                    false
                  >
                    disableClearable
                    options={filtersOptions}
                    onChange={handleAvailableFiltersChange}
                    value={autocompleteValue}
                    getOptionLabel={(option) => {
                      return option.id;
                    }}
                    isMultiple
                    data-test-id="filters__input--filters-choice"
                    placeholder={t('ui__table__filter__select_placeholder')}
                    fullWidth
                  />
                  {hasSelectedFilters && (
                    <FilterClearButtonStyled
                      onClick={handleClearAllFilters}
                      data-test-id="filters__button--clear-all-filters"
                      label={t('ui__table__filter__clear')}
                      iconSize={16}
                    >
                      <CloseIcon />
                    </FilterClearButtonStyled>
                  )}
                </>
              </FilterHeaderLeftStyled>
            </FilterHeaderRowLeftStyled>
            {filtersRightPanelComponent && (
              <FilterHeaderRowRightStyled>
                <FilterHeaderRightStyled>
                  {filtersRightPanelComponent}
                </FilterHeaderRightStyled>
              </FilterHeaderRowRightStyled>
            )}
          </FilterHeaderRowStyled>
          {hasSelectedFilters && (
            <>
              {selectedFilters.map(({ value, type }) => {
                const currentFilter =
                  type === 'base'
                    ? base.entities[value]
                    : additional.entities[value];

                const filterInputProps = currentFilter?.filterInputProps ?? {};

                if (!currentFilter) {
                  return null;
                }

                return (
                  <FilterRow
                    dataTestId={`filters__filter-row-${value}`}
                    filter={currentFilter}
                    filterInputProps={filterInputProps}
                    filters={filters}
                    hasNegativeFilters={hasNegativeFilters}
                    key={value}
                    negative={negative}
                    onClose={handleClearFilter}
                    requiredFilters={requiredFilters}
                    setFilter={handleReactTableFilterChange}
                    setNegative={setNegative}
                    useBrandedDatePicker={useBrandedDatePicker}
                    canEditDateInput={canEditDateInput}
                  />
                );
              })}
            </>
          )}
        </>
      )}
    </FilterRowContainerStyled>
  );
};
