import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Notification } from 'react-ui-kit-exante';

import { useSaveLimitsMutation } from '~/api';
import { resetChangedLimits } from '~/store/limits';

export const useSave = () => {
  const dispatch = useDispatch();

  const [saveLimits] = useSaveLimitsMutation();

  return useCallback(async () => {
    const result = await saveLimits(null);

    if ('data' in result && result.data === true) {
      Notification.success({
        title: 'Limits updated successfully',
      });
    }

    dispatch(resetChangedLimits());
  }, [dispatch, saveLimits]);
};
