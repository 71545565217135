import { isEqual, isFunction, pick } from 'lodash';
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
  ReactNode,
} from 'react';
import {
  Bookmarks,
  Table,
  useTableData,
  Notification,
  Tooltip,
  Modal,
  OnSaveEditableRow,
} from 'react-ui-kit-exante';

import {
  useGetTradeColumnsQuery,
  useLazyGetTradesQuery,
  useRollbackTradeMutation,
  useUpdateTradeMutation,
} from '~/api';
import { defaultTradesResponse } from '~/api/trades/trades.api.constants';
import {
  useGetAccountTypesQuery,
  useGetExecutionCounterpartyQuery,
  useGetLegalEntityTypesQuery,
  useGetSettlementCounterpartyQuery,
  useGetTradeTypesQuery,
} from '~/api/types/types.api';
import { EMPTY_ARRAY, NO_DATA_HEIGHT } from '~/constants';
import {
  useAutocomplete,
  useCurrentUserHasAllBrandingPermission,
  useLogHandleTime,
  usePrevious,
  useSyncTableViewParams,
} from '~/hooks';
import { getDefaultBookmarkResponse } from '~/hooks/useBookmark/helpers';
import { IBookmarkResponseProps } from '~/hooks/useBookmark/types';
import { RollbackIcon } from '~/images/icons/Rollback';
import { WithBookmarks } from '~/shared/components/WithBookmarks';
import {
  calculateCountOfPages,
  clearTableLocalStorageForActionColumn,
  sendNotification,
  transformVariantsToSelectOptions,
  updateAccountIdInParams,
} from '~/shared/utils';
import { getIsFieldExistsAndEmpty } from '~/shared/utils/table';
import { IRefreshActiveTabQuery } from '~/types/refetch';
import { ITrade, ITradeState } from '~/types/trades';

import { DEFAULT_SORTING_TS } from '../TransactionsContainer/sorting';

import { TABLE_ID, TRADE_COLUMNS_ALL, TRADE_COLUMNS_PROPS } from './constants';
import { getAdditionalFilters, getDefaultFilters, useColumns } from './filters';
import { useAdditionalActions } from './hooks';

interface ITradesContainerProps {
  entity?: string;
  globalFilters?: Record<string, unknown>;
  tableId?: string;
  updateRefetch?: (state: IRefreshActiveTabQuery) => void;
  withAddButton?: boolean;
  selectTable?: ReactNode;
}

export const Trades: FC<ITradesContainerProps & IBookmarkResponseProps> = ({
  entity = 'trades-list',
  globalFilters,
  updateRefetch,
  tableId = TABLE_ID,
  withAddButton = true,
  selectedBookmark,
  handleSaveBookmark,
  handleSaveAsNewBookmark,
  handleShareBookmark,
  handleDeleteBookmark,
  selectTable,
}) => {
  const [rollbackTrade] = useRollbackTradeMutation();
  const { setStartHandleTime, logHandleTime } = useLogHandleTime(entity);
  const [fetchTrades] = useLazyGetTradesQuery();
  const [updateTrade, { isLoading: isUpdateTradeLoading }] =
    useUpdateTradeMutation();
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [rollbackData, setRollbackData] = useState<null | {
    orderId: string;
    orderPosition: number;
  }>(null);
  const { data: executionCounterparty } = useGetExecutionCounterpartyQuery();
  const { data: legalEntity } = useGetLegalEntityTypesQuery();
  const { data: settlementCounterparty } = useGetSettlementCounterpartyQuery();
  const { data: tradeTypes } = useGetTradeTypesQuery();
  const { data: tradeColumnsData } = useGetTradeColumnsQuery();
  const { currentUserHasAllBrandingPermission } =
    useCurrentUserHasAllBrandingPermission();

  const tradeColumns = useMemo(() => {
    if (!tradeColumnsData?.length) {
      return TRADE_COLUMNS_ALL;
    }

    return tradeColumnsData;
  }, [tradeColumnsData]);

  const getTrades = useCallback(
    async ({ params }: { params: Record<string, unknown> }) => {
      const isEmptyAccountsList =
        globalFilters && getIsFieldExistsAndEmpty(globalFilters, 'accountId');

      if (isEmptyAccountsList) {
        return defaultTradesResponse;
      }

      const preparedParams = updateAccountIdInParams({
        ...globalFilters,
        ...params,
      });

      setStartHandleTime();

      if (Object.prototype.hasOwnProperty.call(preparedParams, 'fromTo')) {
        const { data } = await fetchTrades(preparedParams);

        return data || defaultTradesResponse;
      }

      return defaultTradesResponse;
    },

    [globalFilters, setStartHandleTime, fetchTrades],
  );
  const defaultFilters = useMemo(() => getDefaultFilters, []);
  const tableDataArgs = useMemo(
    () => ({
      data: { onFetch: getTrades },
      filters: { getDefaultFilters: defaultFilters, required: ['fromTo'] },
      tableId,
      saveViewParamsAfterLeave: true,
      sorting: { getDefaultSorting: () => DEFAULT_SORTING_TS },
    }),
    [defaultFilters, getTrades, tableId],
  );

  const {
    data,
    limit,
    setLimit,
    setPage,
    page,
    isLoading,
    setFilter,
    removeFilter,
    resetFilters,
    setSorting,
    filters,
    fetchData: refetch,
  } = useTableData<ITradeState>(tableDataArgs);
  const getAccountsAutocomplete = useAutocomplete('accounts');
  const getSymbolsAutocomplete = useAutocomplete('symbols');
  const getUsersAutocompleteFn = useAutocomplete('users');

  useEffect(() => {
    if (isFunction(updateRefetch)) {
      updateRefetch({
        refetch,
        isLoading,
      });
    }
  }, [updateRefetch, refetch, isLoading]);

  const total = data?.pagination.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );

  const previousDataTrades = usePrevious(data?.trades);

  const columns = useColumns({
    onFilter: setFilter,
    onRemove: removeFilter,
    types: {
      executionCounterparty: executionCounterparty?.values || [],
      legalEntity: legalEntity?.values || [],
      settlementCounterparty: settlementCounterparty?.values || [],
      tradeType: tradeTypes || [],
    },
    fetchAccounts: getAccountsAutocomplete(),
    fetchSymbols: getSymbolsAutocomplete(),
    fetchUsers: getUsersAutocompleteFn(),
    tradeColumns,
  });

  const { data: accountTypes } = useGetAccountTypesQuery();
  const accountTypesOptions = transformVariantsToSelectOptions(
    accountTypes?.values,
  );

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
        defaultFilters: getDefaultFilters(),
        accountTypes: accountTypesOptions,
      }),
    [removeFilter, setFilter, accountTypesOptions],
  );

  const filterProps = useMemo(
    () => ({
      removeAllFilters: resetFilters,
      additionalFilters,
      filters,
      manualFilters: true,
    }),
    [additionalFilters, filters, resetFilters],
  );
  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [limit, page, pageCount, setLimit, setPage, total],
  );

  useSyncTableViewParams({ pageCount, setPage, tableId });

  const additionalActions = useAdditionalActions(
    withAddButton,
    filters,
    globalFilters,
  );

  useEffect(() => {
    if (data?.trades && !isEqual(previousDataTrades, data?.trades)) {
      logHandleTime();
    }
  }, [logHandleTime, data, previousDataTrades]);

  const bookmarkComponent = useMemo(() => {
    if (globalFilters) {
      return null;
    }

    return (
      <Bookmarks
        initialValues={selectedBookmark}
        onSave={(name) => handleSaveBookmark(name, filters)}
        onSaveAsNew={(name) => handleSaveAsNewBookmark(name, filters)}
        onShare={handleShareBookmark}
        onDelete={handleDeleteBookmark}
      />
    );
  }, [
    filters,
    globalFilters,
    handleSaveBookmark,
    handleSaveAsNewBookmark,
    handleShareBookmark,
    handleDeleteBookmark,
    selectedBookmark,
  ]);

  const displayedColumnKeys = useMemo(
    () =>
      selectedBookmark.columns.length
        ? selectedBookmark.columns
        : [...Object.keys(TRADE_COLUMNS_PROPS), 'actions'],
    [selectedBookmark.columns],
  );

  const successCallback = useCallback(() => {
    sendNotification('Trade has been updated', 'success');
  }, []);

  const errorCallback = useCallback(() => {
    sendNotification('Error updating trade', 'error');
  }, []);

  const onSaveRowHandler: OnSaveEditableRow<ITrade> = useCallback(
    async (_, newRow) => {
      const result = await updateTrade(
        pick(newRow, [
          'orderId',
          'orderPos',
          'clientComment',
          'internalComment',
        ]),
      );

      refetch();

      if (result) {
        successCallback();
      } else {
        errorCallback();
      }
    },
    [refetch, errorCallback, successCallback, updateTrade],
  );

  useEffect(() => {
    clearTableLocalStorageForActionColumn(tableId);
  }, [tableId]);

  const handleRollbackTrade = useCallback(async () => {
    if (!rollbackData) {
      return;
    }

    setShowConfirmModal(false);

    const res = await rollbackTrade({
      orderId: rollbackData.orderId,
      orderPosition: rollbackData.orderPosition,
    });

    if (!('error' in res)) {
      Notification.success({
        title: 'Trade was rolled back',
      });
    }

    setRollbackData(null);
  }, [rollbackData, rollbackTrade]);

  return (
    <>
      <Table
        additionalActions={additionalActions}
        columns={columns}
        data={data?.trades || EMPTY_ARRAY}
        defaultSortBy={DEFAULT_SORTING_TS}
        displayedColumnKeys={displayedColumnKeys}
        filteringProps={filterProps}
        filtersExpanded
        filtersRightPanelComponent={bookmarkComponent}
        hasFilters
        hasPagination
        isFlexLayout
        locale="en-GB"
        isLoading={isLoading || isUpdateTradeLoading}
        manualSortBy
        noDataHeight={NO_DATA_HEIGHT}
        onSort={setSorting}
        canEditDateInput
        {...(currentUserHasAllBrandingPermission
          ? {
              rowActions: {
                show: true,
                onSave: onSaveRowHandler,
                additionalActions: [
                  {
                    label: (
                      <Tooltip title="Rollback">
                        <RollbackIcon />
                      </Tooltip>
                    ),
                    title: 'Rollback',
                    order: 0,
                    onClick: ({ orderId, orderPos }: ITrade) => {
                      setRollbackData({
                        orderId,
                        orderPosition: orderPos,
                      });
                      setShowConfirmModal(true);
                    },
                  },
                ],
                order: 1,
              },
            }
          : {})}
        saveColumnOrder
        saveViewParamsAfterLeave
        serverPaginationProps={serverPaginationProps}
        showScrollbar
        showTableInfo
        tableId={tableId}
        title={selectTable || 'Trades'}
      />
      <Modal
        title=""
        isOpened={showConfirmModal}
        onClose={() => {
          setShowConfirmModal(false);
          setRollbackData(null);
        }}
        confirmButton={{
          handleConfirm: handleRollbackTrade,
          confirmButtonName: 'Rollback',
        }}
        cancelButton={{
          cancelButtonName: 'Cancel',
        }}
        keepMounted={false}
      >
        Rollback trade?
      </Modal>
    </>
  );
};

export const TradesContainer: FC<ITradesContainerProps> = ({
  entity = 'trades-list',
  globalFilters,
  tableId = TABLE_ID,
  updateRefetch,
  withAddButton = true,
  selectTable,
}) => {
  if (globalFilters) {
    const {
      selectedBookmark,
      handleSaveBookmark,
      handleSaveAsNewBookmark,
      handleShareBookmark,
      handleDeleteBookmark,
    } = getDefaultBookmarkResponse('Trades');

    return (
      <Trades
        entity={entity}
        globalFilters={globalFilters}
        handleDeleteBookmark={handleDeleteBookmark}
        handleSaveAsNewBookmark={handleSaveAsNewBookmark}
        handleSaveBookmark={handleSaveBookmark}
        handleShareBookmark={handleShareBookmark}
        selectTable={selectTable}
        selectedBookmark={selectedBookmark}
        tableId={tableId}
        updateRefetch={updateRefetch}
        withAddButton={withAddButton}
      />
    );
  }

  return (
    <WithBookmarks
      component={Trades}
      entity={entity}
      globalFilters={globalFilters}
      pageName="Trades"
      tableId={tableId}
      updateRefetch={updateRefetch}
      withAddButton={withAddButton}
    />
  );
};
