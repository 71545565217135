import { cloneDeep } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { ButtonDatePicker, useTableData } from 'react-ui-kit-exante';

import {
  useGetAccountTypesQuery,
  useGetAssetTypesQuery,
} from '~/api/types/types.api';
import { useAutocomplete, useCurrency, useLocalStoragePolling } from '~/hooks';
import { globalSummaryService } from '~/resources';
import { IGlobalSummaryFilters } from '~/resources/globalSummary/types';
import { DownloadButton } from '~/shared/components';
import {
  calculateCountOfPages,
  formatDateToYYYYMMDD,
  transformDataToSelectOptions,
  transformVariantsToSelectOptions,
} from '~/shared/utils';
import { convertDateToISO, isDatePresent, today } from '~/shared/utils/dates';
import { PROJECT_PREFIX, createLinkToDownloadCSV } from '~/shared/utils/table';
import { IGlobalSummary } from '~/types/accounts';
import { IPaginationParameters } from '~/types/api';

import {
  DEFAULT_SORTING,
  DEFAULT_SORTING_AGGREGATED_DATA,
  DEFAULT_SORTING_CURRENCY_RISK,
  getAdditionalFilters,
} from '../columns';

interface IUseGlobalSummary {
  preCall: () => void;
  tableId: string;
  isAggregatedView?: boolean;
  isByPositionSide?: boolean;
  isCurrencyRisk?: boolean;
}

export function useGlobalSummary({
  preCall,
  tableId,
  isAggregatedView = false,
  isByPositionSide = false,
  isCurrencyRisk = false,
}: IUseGlobalSummary) {
  const currency = useCurrency();
  const [date, setDate] = useState<Date | null>(null);
  const [reconciliationMode, setReconciliationMode] = useState(false);
  const { data: accountTypes } = useGetAccountTypesQuery();
  const { data: assetTypes } = useGetAssetTypesQuery();
  const getAccountsAutocompleteFn = useAutocomplete('accounts');
  const getSymbolsAutocompleteFn = useAutocomplete('symbols');

  const getSummary = useCallback(
    (params: {
      filtersParams: IGlobalSummaryFilters;
      paginationParams: IPaginationParameters;
      sortingParams: unknown;
    }) => {
      preCall();

      return globalSummaryService.getGlobalSummary({
        currency,
        date,
        filtersParams: params.filtersParams,
        isAggregatedView,
        isByPositionSide,
        isCurrencyRisk,
        paginationParams: params.paginationParams,
        reconciliationMode,
        sortingParams: params.sortingParams as Record<string, unknown>,
      });
    },
    [
      currency,
      date,
      isAggregatedView,
      isByPositionSide,
      isCurrencyRisk,
      preCall,
      reconciliationMode,
    ],
  );

  const tableArgs = useMemo(
    () => ({
      data: {
        onFetch: getSummary,
      },
      sorting: {
        getDefaultSorting: () => {
          if (isCurrencyRisk) {
            return DEFAULT_SORTING_CURRENCY_RISK;
          }

          if (isAggregatedView) {
            return DEFAULT_SORTING_AGGREGATED_DATA;
          }

          return DEFAULT_SORTING;
        },
      },
      tableId,
      saveViewParamsAfterLeave: true,
    }),
    [isAggregatedView, isCurrencyRisk, getSummary, tableId],
  );

  const {
    data,
    fetchData,
    filters: filtersTable,
    isLoading,
    limit,
    page,
    params,
    removeFilter,
    resetFilters,
    setFilter,
    setLimit,
    setPage,
    setSorting,
    skip,
  } = useTableData<IGlobalSummary | null>(tableArgs);

  const additionalFilters = useMemo(
    () =>
      getAdditionalFilters({
        onFilter: setFilter,
        onRemove: removeFilter,
      }),
    [removeFilter, setFilter],
  );

  const filteringProps = useMemo(
    () => ({
      additionalFilters,
      removeAllFilters: resetFilters,
      filters: filtersTable,
      manualFilters: true,
    }),
    [additionalFilters, resetFilters, filtersTable],
  );

  const total = data?.pagination?.total || 0;
  const pageCount = useMemo(
    () => calculateCountOfPages(total, limit),
    [limit, total],
  );
  const handleDateChange = useCallback((newDate: Date | null) => {
    if (newDate === null || isDatePresent(newDate)) {
      setDate(newDate);
    }
  }, []);

  const serverPaginationProps = useMemo(
    () => ({
      pageSize: limit,
      skip,
      setPage,
      setPageSize: setLimit,
      pageIndex: page,
      total,
      pageCount,
    }),
    [skip, limit, page, pageCount, setLimit, setPage, total],
  );

  const lsSelectedColumns = useLocalStoragePolling(`${tableId}-columns`);
  const lsViewParams = useLocalStoragePolling(`${tableId}-view-params`);

  const additionalActions = useMemo(() => {
    const modifiedFiltersTable = cloneDeep(filtersTable);

    if (Array.isArray(filtersTable.expirationTime)) {
      if (filtersTable.expirationTime[0]) {
        modifiedFiltersTable.startExpirationTime = convertDateToISO(
          filtersTable.expirationTime[0],
        );
      }

      if (filtersTable.expirationTime[1]) {
        modifiedFiltersTable.endExpirationTime = convertDateToISO(
          filtersTable.expirationTime[1],
        );
      }

      delete modifiedFiltersTable.expirationTime;
    }

    const exportParams = {
      aggregatedView: isAggregatedView,
      byPositionSide: isByPositionSide,
      currencyRisk: isCurrencyRisk,
      fields: (() => {
        try {
          return lsSelectedColumns
            ? JSON.parse(lsSelectedColumns)
            : JSON.parse(lsViewParams || '{}').defaultColumnOrder;
        } catch (e) {
          return [];
        }
      })(),
      limit: Infinity,
      order:
        Array.isArray(params.sorting) && params.sorting?.[0]?.desc
          ? 'desc'
          : 'asc',
      orderBy: Array.isArray(params.sorting) ? params.sorting[0]?.id : 'id',
      reconciliationMode,
      skip: 0,
      ...(date
        ? {
            startReportDate: formatDateToYYYYMMDD(date),
            endReportDate: formatDateToYYYYMMDD(date),
          }
        : null),
      ...modifiedFiltersTable,
    };

    return [
      {
        key: 'historicalMode',
        component: (
          <ButtonDatePicker
            maxDate={today}
            showTodayButton
            onChange={handleDateChange}
            selected={date || null}
            todayButtonText="Real time"
            onToday={() => {
              setDate(today);
            }}
          />
        ),
      },
      {
        key: 'exportCSV',
        component: (
          <DownloadButton
            link={createLinkToDownloadCSV(
              `/api/global-summary/${currency}/csv`,
              exportParams,
            )}
            fileName={tableId.replace(`${PROJECT_PREFIX}-`, '')}
          />
        ),
      },
    ];
  }, [
    currency,
    date,
    filtersTable,
    handleDateChange,
    isAggregatedView,
    isByPositionSide,
    isCurrencyRisk,
    lsSelectedColumns,
    lsViewParams,
    params.sorting,
    reconciliationMode,
    tableId,
  ]);

  const preparedAccountTypes = transformVariantsToSelectOptions(
    accountTypes?.values,
  );
  const preparedAssetTypes = transformDataToSelectOptions(
    assetTypes?.values || [],
    {
      valueKey: 'type',
      labelKey: 'type',
      sort: false,
    },
  );

  return {
    accountTypes: preparedAccountTypes,
    additionalActions,
    assetTypes: preparedAssetTypes,
    currency,
    data,
    date,
    fetchAccounts: getAccountsAutocompleteFn(),
    fetchSymbols: getSymbolsAutocompleteFn(),
    filteringProps,
    isLoading,
    reconciliationMode,
    refetch: fetchData,
    removeFilter,
    setDate: handleDateChange,
    setFilter,
    setReconciliationMode,
    setSorting,
    ...serverPaginationProps,
  };
}
