import { ILimitsSetResponse } from '~/types/limits';

import { FETCH_LIMITS_SETS } from '../endpoints';

import { limitsApi } from './limitsApi';

export const { useGetLimitGroupsQuery, useLazyGetLimitGroupsQuery } =
  limitsApi.injectEndpoints({
    endpoints: (builder) => ({
      getLimitGroups: builder.query<ILimitsSetResponse[], void>({
        query: () => ({
          url: FETCH_LIMITS_SETS,
        }),
        providesTags: ['LimitGroups'],
      }),
    }),
  });
