import { createSlice } from '@reduxjs/toolkit';
import {
  clone,
  cloneDeep,
  get,
  groupBy,
  isEmpty,
  mapValues,
  set,
  unset,
} from 'lodash';

import {
  getLimitsTreeMatchFulfilled,
  searchLimitsInstrumentsMatchFulfilled,
} from '~/api';
import { REGEX_LAST_SEGMENT_IN_PATH } from '~/constants';
import { deepSet, getAllPathsSet } from '~/shared/utils';
import { formatPercentage } from '~/store/overnights/helpers';
import { LimitsTree } from '~/types/limits/tree';
import type { TApplicationState } from '~/types/store';

import { INITIAL_STATE } from './constants';
import { updateSubRows } from './helpers';

interface IUpdateNodeValuePayload {
  payload: {
    path: string;
    value: number | boolean;
    column: string;
  };
}

export const limitsSlice = createSlice({
  name: 'limits',
  initialState: INITIAL_STATE,
  reducers: {
    expandedRowsSet: (state, { payload }) => {
      const [expanded, id, value] = payload;

      state.expandedRows = {
        ...expanded,
        [id]: value,
      };
    },
    setRefreshMode: (state) => {
      state.refreshMode.tree = clone(
        isEmpty(state.tree.searchMode)
          ? state.tree.standardMode
          : state.tree.searchMode,
      );

      state.refreshMode.isEnabled = true;
    },
    resetRefreshMode: (state) => {
      state.refreshMode.tree = [];
      state.refreshMode.isEnabled = false;
    },
    resetTable: (state) => {
      state.changedLimits = cloneDeep(INITIAL_STATE.changedLimits);

      if (isEmpty(state.tree.searchMode)) {
        state.tree.standardMode = cloneDeep(state.tree.initial);

        state.pathByPositionInTree.standardMode = cloneDeep(
          state.pathByPositionInTree.initial,
        );
      } else {
        state.tree.searchMode = cloneDeep(state.tree.initial);

        state.pathByPositionInTree.searchMode = cloneDeep(
          state.pathByPositionInTree.initial,
        );
      }

      state.expandedRows = {};
      state.downloadedPaths = [];
    },
    resetChangedLimits: (state) => {
      state.changedLimits = cloneDeep(INITIAL_STATE.changedLimits);
    },
    resetTableForRefreshInStandardMode: (state) => {
      state.changedLimits = cloneDeep(INITIAL_STATE.changedLimits);

      state.tree.standardMode = cloneDeep(INITIAL_STATE.tree.standardMode);

      state.pathByPositionInTree.standardMode = cloneDeep(
        state.pathByPositionInTree.initial,
      );
    },
    resetTableForRefreshInSearchMode: (state) => {
      state.changedLimits = cloneDeep(INITIAL_STATE.changedLimits);

      state.tree.searchMode = cloneDeep(state.tree.initial);

      state.pathByPositionInTree.searchMode = cloneDeep(
        state.pathByPositionInTree.initial,
      );
    },
    updateInstrumentValue: (state, { payload }) => {
      const { path, value, column } = payload;
      const isStandardMode = isEmpty(state.tree.searchMode);

      const row = get(
        state.tree[isStandardMode ? 'standardMode' : 'searchMode'],
        state.pathByPositionInTree[
          isStandardMode ? 'standardMode' : 'searchMode'
        ][path],
      );

      row[column] = value;

      state.changedLimits.instruments[path] = clone(row);
    },
    updateNodeValue: (state, { payload }: IUpdateNodeValuePayload) => {
      const { path, value, column } = payload;
      const isStandardMode = isEmpty(state.tree.searchMode);

      const row = get(
        state.tree[isStandardMode ? 'standardMode' : 'searchMode'],
        state.pathByPositionInTree[
          isStandardMode ? 'standardMode' : 'searchMode'
        ][path],
      );

      row[column] = value;

      state.changedLimits.nodes[path] = clone(row);

      // override don't should update sub rows https://jira.exante.eu/browse/WBU-922
      if (column !== 'override') {
        updateSubRows(row, column, value);
      }
    },
    insertInstruments: (state, { payload }) => {
      const { path, instruments } = payload;

      deepSet(
        state.tree.standardMode,
        `${state.pathByPositionInTree.standardMode[path]}.subRows`,
        instruments,
      );

      if (!new Set(state.downloadedPaths).has(path)) {
        state.downloadedPaths = [...state.downloadedPaths, path];
      }

      state.pathByPositionInTree.standardMode = {
        ...state.pathByPositionInTree.standardMode,
        ...instruments.reduce(
          (acc: Record<string, string>, item: LimitsTree, index: number) => {
            const newPath = `${state.pathByPositionInTree.standardMode[path]}.subRows.${index}`;
            acc[item.path] = newPath;

            return acc;
          },
          {} as Record<string, string>,
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getLimitsTreeMatchFulfilled, (state, { payload }) => {
      state.tree.initial = cloneDeep(payload.tree);
      state.tree.standardMode = cloneDeep(payload.tree);

      state.pathByPositionInTree.initial = cloneDeep(
        payload.pathByPositionInTree,
      );
      state.pathByPositionInTree.standardMode = cloneDeep(
        payload.pathByPositionInTree,
      );
    });
    builder.addMatcher(
      searchLimitsInstrumentsMatchFulfilled,
      (state, { payload }) => {
        if (payload.isFinish) {
          const groupedInstrumentsByPath = groupBy(
            payload.instruments,
            (item) => item.path.replace(REGEX_LAST_SEGMENT_IN_PATH, ''),
          );

          const pathsWithInstruments: string[] = [];

          const tree = cloneDeep(state.tree.initial);

          const positionByIdInTreeForCalculate = clone(
            state.pathByPositionInTree.initial,
          );

          const positionByIdInTreeForState = clone(
            state.pathByPositionInTree.initial,
          );

          Object.entries(groupedInstrumentsByPath).forEach(
            ([path, instruments]) => {
              const position = positionByIdInTreeForCalculate[path];

              set(
                tree,
                `${position}.subRows`,
                instruments.map((instrument) =>
                  mapValues(instrument, (value, key) => {
                    if (key.startsWith('markup')) {
                      return formatPercentage(Number(value) || 0);
                    }

                    return value || 0;
                  }),
                ),
              );
              pathsWithInstruments.push(path);
              delete positionByIdInTreeForCalculate[path];

              instruments.forEach((instrument, index) => {
                positionByIdInTreeForState[
                  instrument.path
                ] = `${position}.subRows.${index}`;
              });
            },
          );

          const allPathsSet = getAllPathsSet(
            pathsWithInstruments,
            REGEX_LAST_SEGMENT_IN_PATH,
          );

          Object.entries(positionByIdInTreeForCalculate).forEach(
            ([path, position]) => {
              if (!allPathsSet.has(path)) {
                unset(tree, position);
              }
            },
          );

          state.tree.searchMode = tree;
          state.expandedRows = true;
          state.pathByPositionInTree.searchMode = positionByIdInTreeForState;
        }
      },
    );
  },
});

export const selectLimitsTree = (state: TApplicationState) => {
  if (state.limits.refreshMode.isEnabled) {
    return state.limits.refreshMode.tree;
  }

  if (!isEmpty(state.limits.tree.searchMode)) {
    return state.limits.tree.searchMode;
  }

  return state.limits.tree.standardMode;
};

export const selectExpandedRows = (state: TApplicationState) =>
  state.limits.expandedRows;

export const selectDownloadedPaths = (state: TApplicationState) =>
  state.limits.downloadedPaths;

export const selectIsSearchMode = (state: TApplicationState) =>
  !isEmpty(state.limits.tree.searchMode);

export const selectHasChangedLimits = (state: TApplicationState) =>
  !isEmpty(state.limits.changedLimits.instruments) ||
  !isEmpty(state.limits.changedLimits.nodes);

export const selectIsRefreshMode = (state: TApplicationState) =>
  state.limits.refreshMode.isEnabled;

export const {
  expandedRowsSet,
  insertInstruments,
  resetChangedLimits,
  resetTable,
  resetTableForRefreshInStandardMode,
  resetTableForRefreshInSearchMode,
  updateInstrumentValue,
  updateNodeValue,
  setRefreshMode,
  resetRefreshMode,
} = limitsSlice.actions;
