import { useFormContext } from 'react-hook-form';
import { useSelector } from 'react-redux';

import { useGetNonTradingCommissionGroupsQuery } from '~/api';
import {
  useGetAccountPurposeTypesQuery,
  useGetAccountTypesQuery,
  useGetOwnershipTypeQuery,
} from '~/api/types/types.api';
import { EMPTY_OPTION_NULLABLE } from '~/constants';
import { useProviders, useBrandingList } from '~/hooks';
import { getAccountPurposeOptionsByType } from '~/pages/AccountPage/constants';
import { transformVariantsToSelectOptions } from '~/shared/utils';
import { transformCommissionGroupsToOptions } from '~/shared/utils/commissions';
import { selectIsNavCurrenciesOptions } from '~/store/currencies';

const ACCOUNT_TYPE = 'account.type';

export function useOptions(counterpartyId?: string | null) {
  const { accountProviders } = useProviders();
  const { data: accountPurposeTypes } = useGetAccountPurposeTypesQuery();
  const { data: accountTypes } = useGetAccountTypesQuery();
  const { data: ownershipTypes } = useGetOwnershipTypeQuery();
  const { data: commissionGroupsTypes } =
    useGetNonTradingCommissionGroupsQuery();

  const { watch, getValues } = useFormContext();
  const accountType = watch(ACCOUNT_TYPE);

  const autoconversionCurrenciesOptions = useSelector(
    selectIsNavCurrenciesOptions,
  );

  const { brandingList } = useBrandingList();

  return {
    accountProviders:
      counterpartyId && accountProviders[counterpartyId]
        ? accountProviders[counterpartyId]
        : [EMPTY_OPTION_NULLABLE],
    accountPurpose: getAccountPurposeOptionsByType(
      accountType,
      getValues(ACCOUNT_TYPE),
      accountPurposeTypes?.values || [],
    ),
    accountTypes: transformVariantsToSelectOptions(accountTypes?.values),
    commissionGroupsTypes: transformCommissionGroupsToOptions(
      commissionGroupsTypes,
    ),
    brandingList,
    currenciesAutoConversion: [
      EMPTY_OPTION_NULLABLE,
      ...autoconversionCurrenciesOptions,
    ],
    ownershipType: transformVariantsToSelectOptions(ownershipTypes?.values),
  };
}
