import { styled, keyframes } from 'react-ui-kit-exante';

import {
  FormInputContainer,
  FormToggleButtonGroupContainer,
} from '~/containers/form';
import { DeleteButton, FormItem, RestoreButton } from '~/shared/components';
import { Disabled } from '~/styles';

const createFlashingAnimation = (color: string) => {
  return keyframes`
  0% { background-color: initial; }
  50% { background-color: ${color}; }
  100% { background-color: initial; }
`;
};

export const StyledFormItem = styled('li')(({ theme }) => {
  const flashing = createFlashingAnimation(theme.color.table.bg.selected);

  return {
    padding: '24px',
    borderTop: `1px solid ${theme.color.line.primary}`,
    position: 'relative',
    listStyle: 'none',

    '&[data-is-new="true"]': {
      order: -1,
    },
    '&[data-flashing="true"]': {
      animation: `${flashing} 2s`,
    },
  };
});

export const StyledFormItemFields = styled('div')(() => ({
  gap: 24,
  display: 'flex',
  flexDirection: 'column',

  '&[data-is-deleted="true"]': Disabled(),
}));

export const StyledFormRow = styled(FormItem)(() => ({
  '> *': {
    maxWidth: 268,
  },
}));

export const StyledFormInputContainer = styled(FormInputContainer)(
  ({ theme }) => ({
    '*[readonly]': {
      color: theme.color.typo.secondary,
    },
  }),
);

export const StyledDeleteButton = styled(DeleteButton)(() => ({
  height: 24,
  position: 'absolute',
  right: 24,
  top: 36,
  width: 24,
}));

export const StyledRestoreButton = styled(RestoreButton)(() => ({
  position: 'absolute',
  right: 24,
  top: 36,
}));

export const StyledToggleButton = styled(FormToggleButtonGroupContainer)(
  () => ({
    display: 'block',

    '& button': {
      width: 'auto',
    },
  }),
);
