import { createTheme } from 'react-ui-kit-exante';

// this is the fix for problem with types
// https://exante.slack.com/archives/C035P49RZQA/p1699875622217889?thread_ts=1699622144.725389&cid=C035P49RZQA
declare module '@mui/material/styles/createTheme' {
  interface Theme {
    color: Record<string, any>;
    font: Record<string, any>;
    size: Record<string, any>;
  }
}

const defaultThemeValues = {
  palette: {
    primary: {
      light: '#af1430',
      main: '#007F39',
    },
    success: {
      main: '#409F6B',
    },
    text: {
      primary: '#252C2E',
      secondary: '#4E5D60',
      disabled: '#B8BBB7',
    },
    warning: {
      main: '#9F4040',
    },
  },
  color: {
    bg: {
      basic: '#F4F5F6', // Background Global
      primary: '#FFFFFF', // Background Block
      secondary: '#F5F7F7',
      promo: '#4E5D60',
      source: '#ECF9F2',
      warning: '#F9ECED',
      radical: '#FEF6E7',
    },
    button: {
      warning: {
        gr: {
          default: 'linear-gradient(180deg, #F1A615 0%, #EA9C0B 100%)',
          focus: '#EA9C0B',
          hover: 'linear-gradient(180deg, #F1A615 0%, #EA9C0B 100%)',
        },
        typo: {
          default: '#FFFFFF',
          hover: '#FFFFFF',
          focus: '#CCE6D8',
          disabled: '#FFFFFF',
        },
        corner: {
          default: '#EA9C0B',
        },
      },
      primary: {
        gr: {
          default: 'linear-gradient(180deg, #029E47 0%, #01803A 100%)',
          focus: '#01803A',
          hover: 'linear-gradient(180deg, #01C056 0%, #01803A 100%)',
        },
        bg: {
          default: '#007F39', // Primary Button Default
          hover: '#00A54A', // Primary Button Hover
          focus: '#00652E', // Primary Button Focus
        },
        typo: {
          default: '#FFFFFF', // Base Inverse
          hover: '#FFFFFF', // Base Inverse
          focus: '#FFFFFF', // Base Inverse
          disabled: '#FFFFFF', // Base Inverse
        },
        corner: {
          default: '#01662E',
        },
      },
      radical: {
        gr: {
          default: 'linear-gradient(180deg, #BF3B4B 0%, #8B2D2D 100%)',
          focus: '#8B2D2D',
          hover: 'linear-gradient(180deg, #CA4747 0%, #A63636 100%)',
        },
        bg: {
          default: '#BF3B4A', // Radical Button Default
          hover: '#CB5664', // Radical Button Hover
          focus: '#A2323F', // Radical Button Focus
        },
        typo: {
          default: '#FFFFFF',
          hover: '#FFFFFF',
          focus: '#E6CCCC',
          disabled: '#FFFFFF',
        },
        corner: {
          default: '#6F2626',
        },
      },
      secondary: {
        gr: {
          default: 'linear-gradient(180deg, #F0F0F0 0%, #E4E4E4 100%)',
          focus: '#E6E6E6',
          hover: 'linear-gradient(180deg, #F9F9F9 0%, #F0F0F0 100%)',
        },
        bg: {
          default: '#F3F5F5', // Secondary Button Default
          hover: '#F5F7F7', // Secondary Button Hover
          focus: '#D3DADA', // Secondary Button Focus
        },
        typo: {
          default: '#4E5D60',
          hover: '#252C2E',
          focus: '#BABABA',
          disabled: '#4E5D60',
        },
        corner: {
          default: '#D3D3D3',
        },
      },
    },
    checkbox: {
      primary: {
        checked: {
          bg: '#007F39', // Button Primary Default
          border: {
            default: '#007F39', // Base Primary Action
            focus: '#007F39', // Base Primary Action
            hover: '#007F39', // Base Primary Action
          },
          boxShadow: {
            default:
              'inset 0 -4.8px 9.6px rgba(33, 229, 100, 0.24), inset 0 4.8px 9.6px rgba(12, 211, 161, 0.41)',
            focus: 'none',
            hover: 'none',
          },
          iconColor: '#FFFFFF', // Base Inverse
          labelColor: {
            default: '#6D8288', // Base Secondary Action
            hover: '#49575B', // Base Secondary
            focus: '#242B2D', // Base Primary
          },
        },
        nonChecked: {
          bg: '#FFFFFF', // Background Block
          border: {
            default: '#6D8288', // Base Secondary Action
            hover: '#4E5D60', // Base Secondary
            focus: '#007F39', // Button Primary Default
          },
          boxShadow: {
            default: 'none',
            hover: 'none',
            focus: 'none',
          },
          iconColor: '#FFFFFF', // Base Inverse
          labelColor: {
            default: '#6D8288', // Base Secondary Action
            hover: '#49575B', // Base Secondary
            focus: '#242B2D', // Base Primary
          },
        },
      },
      warning: {
        checked: {
          bg: '#EA9C0B', // Warning Controls Default
          border: {
            default: '#EA9C0B', // Warning Controls Default
            focus: '#EA9C0B', // Warning Controls Default
            hover: '#F6B741', // Warning Controls hover
          },
          boxShadow: {
            default:
              'inset 0 -4.8px 9.6px rgba(255, 176, 26, 0.24), inset 0 4.8px 9.6px rgba(255, 229, 181, 0.41)',
            focus: 'none',
            hover: 'none',
          },
          iconColor: '#FFFFFF', // Base Inverse
          labelColor: {
            default: '#6D8288', // Base Secondary Action
            hover: '#49575B', // Base Secondary
            focus: '#242B2D', // Base Primary
          },
        },
        nonChecked: {
          bg: '#FFFFFF', // Background Block
          border: {
            default: '#EA9C0B', // Warning Controls Default
            hover: '#F6B741', // Warning Controls Hover
            focus: '#C78409', // Warning Controls Focus
          },
          boxShadow: {
            default: 'none',
            focus: 'none',
            hover: 'none',
          },
          iconColor: '#FFFFFF', // Base Inverse
          labelColor: {
            default: '#6D8288', // Base Secondary Action
            hover: '#49575B', // Base Secondary
            focus: '#242B2D', // Base Primary
          },
        },
      },
      radical: {
        checked: {
          bg: '#BF3B4A', // Button Radical Default
          border: {
            default: '#BF3B4A', // Button Radical Default
            focus: '#BF3B4A', // Button Radical Default
            hover: '#CB5664', // Button Radical Hover
          },
          boxShadow: {
            default:
              'inset 0 -4.8px 9.6px rgba(191, 59, 74, 0.24), inset 0 4.8px 9.6px rgba(191, 59, 74, 0.41)',
            focus: 'none',
            hover: 'none',
          },
          iconColor: '#FFFFFF', // Base Inverse
          labelColor: {
            default: '#6D8288', // Base Secondary Action
            hover: '#49575B', // Base Secondary
            focus: '#242B2D', // Base Primary
          },
        },
        nonChecked: {
          bg: '#FFFFFF', // Background Block
          border: {
            default: '#BF3B4A', // Button Radical Default
            hover: '#CB5664', // Button Radical Hover
            focus: '#BF3B4A', // Button Radical Default
          },
          boxShadow: {
            default: 'none',
            focus: 'none',
            hover: 'none',
          },
          iconColor: '#FFFFFF', // Base Inverse
          labelColor: {
            default: '#6D8288', // Base Secondary Action
            hover: '#49575B', // Base Secondary
            focus: '#242B2D', // Base Primary
          },
        },
      },
    },
    controls: {
      gr: 'linear-gradient(180deg, #029E47 0%, #01803A 100%)',
    },
    defaultBoxShadow: '0 4px 8px 0 rgba(37, 44, 46, 0.1)',
    dropdown: {
      bg: {
        default: '#FFFFFF', // Controls State Default
      },
      border: '#EFF2F2', // Controls State Border
      error: '#BF3B4B', // Base Radical
      list: {
        bg: {
          focus: '#F5F7F7', // Controls State Focus
          hover: '#F8F9F9', // Controls State Hover
        },
      },
      chip: {
        bg: '#F5F7F7', // Controls State Filled
      },
    },
    icon: {
      action: '#007F39', // Base Primary Action
      ghost: '#94A4A9', // Base Ghost
      inverse: '#FFFFFF', // Base Inverse
      primary: '#242B2D', // Base Primary
      promo: '#49575B', // Base Secondary
      radical: '#BF3B4A', // Base Radical
      secondary: '#6D8288', // Base Secondary Action
      source: '#339961', // Base Source
      warning: '#EA9C0B', // Base Warning
    },
    indicator: {
      bg: {
        basic: '#F5F7F7',
      },
    },
    circularProgress: {
      background: '#F4F5F6', // Background Global
      foreground: '#339961', // Base Source
    },
    input: {
      bg: {
        default: '#FFFFFF', // Controls State Default
        hover: '#F8F9F9', // Controls State Hover
        focus: '#F5F7F7', // Controls State Focus
      },
      border: '#EFF2F2', // Controls State Border
      error: '#BF3B4A', // Base Radical
      warning: '#EA9C0B', // Base Warning
      transparentWarning: 'rgba(236, 159, 11, 0.3)', // remove & replace in Switch & ToggleButton components
    },
    modal: {
      bg: '#FFFFFF', // Background Modal
    },
    line: {
      primary: '#EEF1F1', // Border Secondary
    },
    notifications: {
      success: '#339961', // Base Source
      error: '#BF3B4A', // Base Radical
      warning: '#EA9C0B', // Base Warning
    },
    typo: {
      action: '#007F39', // Base Primary Action
      ghost: '#94A4A9', // Base Ghost
      hover: '#03BF56', // Base Hover
      inverse: '#FFFFFF', // Base Inverse
      primary: '#242B2D', // Base Primary
      promo: '#49575B', // Base Secondary
      radical: '#BF3B4A', // Base Radical
      secondary: '#6D8288', // Base Secondary Action
      source: '#339961', // Base Source
      warning: '#EA9C0B', // Base Warning
    },
    table: {
      bg: {
        basic1: '#FAFBFB', // Table Base 1
        basic2: '#FFFFFF', // Table Base 2
        radical: '#F9ECED', // Table Radical Default
        source: '#ECF9F2', // Table Source Default
        hover: '#F5F7F7', // Table Hover
        selected: '#CBE3CD', // Table Selected
      },
      boxShadow: {
        basic1: 'inset 0 1px 0 #EEF1F1', // Border Secondary
      },
      header: {
        default: '#818C99',
      },
    },
    datepicker: {
      background: '#FFFFFF', // Background Block
      colorWeek: '#242B2D', // Base Primary
      colorDay: '#6D8288', // Base Secondary Action
      daySelected: '#F5F7F7', // Controls State Focus
      dayHover: '#F8F9F9', // Controls State Hover
      dayBorder: '#F5F7F7', // Controls State Focus
    },
    loader: {
      color: '#6D8288', // Base Secondary Action
    },
    tree: {
      hover: '#F5F5F5',
      focused: '#007f3933',
      expanded: '#ECF5F0',
    },
  },
  effect: {
    controls: {
      warning:
        'inset 0 -4.8px 9.6px rgba(236, 159, 11, 0.24), inset 0 4.8px 9.6px rgba(236, 159, 11, 0.41)',
      inter:
        'inset 0 -4.8px 9.6px rgba(33, 229, 100, 0.24), inset 0 4.8px 9.6px rgba(12, 211, 161, 0.41)',
      drop: '0 2.32257px 6.19352px rgba(0, 0, 0, 0.15)',
    },
  },
  font: {
    main: 'Inter, sans-serif',
    header: 'FoxSansPro, sans-serif',
  },
  size: {
    loader: {
      md: '16px',
      lg: '32px',
    },
    text: {
      sm: '12px',
      md: '13px',
      lg: '15px',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      tablet: 512,
      sm: 768,
      md: 1024,
      lg: 1280,
      lr: 1440,
      xl: 1920,
    },
  },
};

export const defaultTheme = createTheme(defaultThemeValues);
