import { merge } from 'lodash';
import { ReactNode, useMemo } from 'react';
import { createTheme, ThemeProvider } from 'react-ui-kit-exante';

import { defaultTheme } from './defaultTheme';

export const ComposedThemeProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const brandingTheme = window.WEB_BO_UI?.theme;

  const themeValue = useMemo(() => {
    if (!brandingTheme) {
      return defaultTheme;
    }

    return createTheme(merge({}, defaultTheme, brandingTheme));
  }, [brandingTheme]);

  return <ThemeProvider theme={themeValue}>{children}</ThemeProvider>;
};
