/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */

import { ChangeEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IColumn, IRowExpand, Notification } from 'react-ui-kit-exante';

import { useLazyGetLimitsInstrumentsQuery } from '~/api';
import { EN_DASH, LIMITS_MESSAGES } from '~/constants';
import { updateInstrumentValue, updateNodeValue } from '~/store/limits';
import { selectLayer } from '~/store/treeStructure';
import { RowType } from '~/types/common';
import { ILimitTree } from '~/types/limits';
import { FilterLayers } from '~/types/treeStructure';

import { StyledInput, StyledButton } from './ValueCellStyled';

interface IValueCell {
  column: IColumn<ILimitTree>;
  value: null | string | number;
  row: IRowExpand<ILimitTree>;
}

export const ValueCell = ({ column, value, row }: IValueCell) => {
  const isNodeCell = row.original.rowType === RowType.Node;

  const dispatch = useDispatch();
  const layer = useSelector(selectLayer);
  const [getInstruments] = useLazyGetLimitsInstrumentsQuery();

  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState<string | number>(() => {
    if (value === null) {
      return isNodeCell ? EN_DASH : 0;
    }

    return value;
  });

  const onClickHandler = useCallback(() => {
    if (!row.values.override && FilterLayers.Default !== layer) {
      Notification.warning({
        title: LIMITS_MESSAGES.NON_OVERRIDE_LIMIT,
      });

      return;
    }

    setIsEdit(true);
  }, [layer, row]);

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue(e.target.value);
    },
    [setInputValue],
  );

  const onBlurHandler = useCallback(async () => {
    const actionForUpdateValue = isNodeCell
      ? updateNodeValue
      : updateInstrumentValue;

    dispatch(
      actionForUpdateValue({
        path: row.original.path,
        value: Number(inputValue),
        column: column.id,
      }),
    );

    setIsEdit(false);
  }, [column, dispatch, inputValue, isNodeCell, row, setIsEdit]);

  if (isEdit) {
    return (
      <StyledInput
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus
        type="number"
        value={inputValue}
        onChange={onChangeHandler}
        onBlur={onBlurHandler}
      />
    );
  }

  return (
    <StyledButton onClick={onClickHandler}>
      {value === null || Number.isNaN(value) ? EN_DASH : String(value)}
    </StyledButton>
  );
};
