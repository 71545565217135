import { getTableId } from '~/shared/utils';
import { TTradeColumns } from '~/types/trades';

export const TABLE_ID = getTableId('Trades');

export const TRADE_COLUMNS_ALL: TTradeColumns[] = [
  {
    id: 'orderId',
  },
  {
    id: 'orderPos',
  },
  {
    id: 'accountId',
  },
  {
    id: 'symbolId',
  },
  {
    id: 'symbolType',
  },
  {
    id: 'isin',
  },
  {
    id: 'quantity',
  },
  {
    id: 'price',
  },
  {
    id: 'currency',
  },
  {
    id: 'gwTime',
  },
  {
    id: 'counterpartyAccountId',
  },
  {
    id: 'exchangeOrderId',
  },
  {
    id: 'side',
  },
  {
    id: 'commission',
  },
  {
    id: 'commissionCurrency',
  },
  {
    id: 'isForcedCloseOut',
  },
  {
    id: 'userId',
  },
  {
    id: 'counterpartyClientId',
  },
  {
    id: 'executionCounterparty',
  },
  {
    id: 'valueDate',
  },
  {
    id: 'clientComment',
  },
  {
    id: 'internalComment',
  },
  {
    id: 'contractMultiplier',
  },
  {
    id: 'settlementCounterparty',
  },
  {
    id: 'clientType',
  },
  {
    id: 'isManual',
  },
  {
    id: 'settlementBrokerAccountId',
  },
  {
    id: 'settlementBrokerClientId',
  },
  {
    id: 'who',
  },
  {
    id: 'injectedBy',
  },
  {
    id: 'tradeType',
  },
  {
    id: 'pnl',
  },
  {
    id: 'convertedPnl',
  },
  {
    id: 'closedQuantity',
  },
  {
    id: 'tradedVolume',
  },
  {
    id: 'counterpartyTime',
  },
  {
    id: 'tradeTime',
  },
  {
    id: 'tradeDate',
  },
  {
    id: 'feePolicy',
  },
  {
    id: 'legalEntity',
  },
  {
    id: 'symbolTypeOverride',
  },
  {
    id: 'source',
  },
  {
    id: 'legs',
  },
  {
    id: 'convertedSum',
  },
  {
    id: 'providerName',
  },
  {
    id: 'gatewayName',
  },
  {
    id: 'accountName',
  },
  {
    id: 'executionVenue',
  },
  {
    id: 'execBroker',
  },
  {
    id: 'lastMarket',
  },
  {
    id: 'remainedQuantity',
  },
  {
    id: 'remainedQuantityTotal',
  },
  {
    id: 'filledQuantity',
  },
  {
    id: 'filledQuantityTotal',
  },
  {
    id: 'chainId',
  },
  {
    id: 'executionSchemeId',
  },
  {
    id: 'logicType',
  },
  {
    id: 'markup',
  },
  {
    id: 'uti',
  },
];

export const TRADE_COLUMNS_PROPS: Record<string, Record<string, unknown>> = {
  orderId: {
    Header: 'Order id',
    accessor: 'orderId',
    onFilter: true,
    onRemove: true,
  },
  orderPos: {
    Header: 'Order position',
    accessor: 'orderPos',
    disableFilters: true,
  },
  accountId: {
    Header: 'Account',
    accessor: 'accountId',
    onFilter: true,
    onRemove: true,
  },
  symbolId: {
    Header: 'Symbol',
    accessor: 'symbolId',
    onFilter: true,
    onRemove: true,
  },
  symbolType: {
    Header: 'Symbol type',
    accessor: 'symbolType',
    filterOptions: true,
    onFilter: true,
    onRemove: true,
    type: 'select',
  },
  quantity: {
    Header: 'Quantity',
    accessor: 'quantity',
    disableFilters: true,
    formatting: 'number',
  },
  price: {
    Header: 'Price',
    accessor: 'price',
    disableFilters: true,
    formatting: 'number',
  },
  currency: {
    Header: 'Currency',
    accessor: 'currency',
    disableFilters: true,
    disableSortBy: true,
  },
  gwTime: {
    Header: 'Gw time',
    accessor: 'gwTime',
    disableFilters: true,
    formatting: 'dateTimeUTC',
  },
  counterpartyAccountId: {
    Header: 'Counterparty account',
    accessor: 'counterpartyAccountId',
    disableFilters: true,
  },
  exchangeOrderId: {
    Header: 'Exchange order id',
    accessor: 'exchangeOrderId',
    onFilter: true,
    onRemove: true,
  },
  side: {
    Header: 'Side',
    accessor: 'side',
    disableFilters: true,
  },
  commission: {
    Header: 'Commission',
    accessor: 'commission',
    disableFilters: true,
    formatting: 'number',
  },
  commissionCurrency: {
    Header: 'Commission currency',
    accessor: 'commissionCurrency',
    disableFilters: true,
  },
  isForcedCloseOut: {
    Header: 'Forced close out',
    accessor: 'isForcedCloseOut',
    disableFilters: true,
    disableSortBy: true,
  },
  userId: {
    Header: 'User',
    accessor: 'userId',
    onFilter: true,
    onRemove: true,
  },
  counterpartyClientId: {
    Header: 'Counterparty client',
    accessor: 'counterpartyClientId',
    disableFilters: true,
  },
  executionCounterparty: {
    Header: 'Execution counterparty',
    accessor: 'executionCounterparty',
    filterOptions: true,
    onFilter: true,
    onRemove: true,
    type: 'select',
  },
  valueDate: {
    Header: 'Value date',
    accessor: 'valueDate',
    disableFilters: true,
    formatting: 'dateUTC',
  },
  clientComment: {
    Header: 'Client comment',
    accessor: 'clientComment',
    disableFilters: true,
    editParams: {
      editable: true,
    },
  },
  internalComment: {
    Header: 'Internal comment',
    accessor: 'internalComment',
    disableFilters: true,
    editParams: {
      editable: true,
    },
  },
  contractMultiplier: {
    Header: 'Contract multiplier',
    accessor: 'contractMultiplier',
    disableSortBy: true,
    disableFilters: true,
    formatting: 'number',
  },
  settlementCounterparty: {
    Header: 'Settlement counterparty',
    accessor: 'settlementCounterparty',
    filterOptions: true,
    onFilter: true,
    onRemove: true,
    type: 'select',
  },
  clientType: {
    Header: 'Client type',
    accessor: 'clientType',
    disableFilters: true,
  },
  isManual: {
    Header: 'Manual',
    accessor: 'isManual',
    disableFilters: true,
  },
  who: {
    Header: 'Who',
    accessor: 'who',
    disableFilters: true,
  },
  tradeType: {
    Header: 'Trade type',
    accessor: 'tradeType',
    filterOptions: true,
    onFilter: true,
    onRemove: true,
    type: 'select',
  },
  pnl: {
    Header: 'PNL',
    accessor: 'pnl',
    disableFilters: true,
    formatting: 'number',
  },
  closedQuantity: {
    Header: 'Closed quantity',
    accessor: 'closedQuantity',
    disableFilters: true,
    formatting: 'number',
  },
  tradeVolume: {
    Header: 'Traded volume',
    accessor: 'tradedVolume',
    disableFilters: true,
    formatting: 'number',
  },
  counterpartyTime: {
    Header: 'Counterparty time',
    accessor: 'counterpartyTime',
    disableFilters: true,
    formatting: 'dateTimeUTC',
  },
  tradeTime: {
    Header: 'Trade time',
    accessor: 'tradeTime',
    disableFilters: true,
    formatting: 'dateTimeUTC',
  },
  legalEntity: {
    Header: 'Legal entity',
    accessor: 'legalEntity',
    disableSortBy: true,
    type: 'multiSelect',
    filterOptions: true,
    onFilter: true,
    onRemove: true,
  },
  source: {
    Header: 'Source',
    accessor: 'source',
    onFilter: true,
    onRemove: true,
  },
  execBroker: {
    Header: 'Exec broker',
    accessor: 'execBroker',
    disableFilters: true,
    disableSortBy: true,
  },
};
