import { IColumn } from 'react-ui-kit-exante';

import { NameCell } from '~/shared/components';
import { LimitsTree } from '~/types/limits';

import { OverrideCell } from './components/OverrideCell';
import { UpperCapCell } from './components/UpperCapCell';
import { ValueCell } from './components/ValueCell';

export const useColumns = (): IColumn<LimitsTree>[] => [
  {
    Cell: NameCell,
    Header: 'Name',
    accessor: 'displayName',
    disableSortBy: true,
    id: 'name',
    width: 1000,
  },
  {
    id: 'override',
    Cell: OverrideCell,
    Header: 'Override',
    accessor: 'override',
    disableSortBy: true,
  },
  {
    id: 'negativeLim',
    Cell: ValueCell,
    Header: 'Negative',
    accessor: 'negativeLim',
    disableSortBy: true,
    maxWidth: 100,
  },
  {
    id: 'positiveLim',
    Cell: ValueCell,
    Header: 'Positive',
    accessor: 'positiveLim',
    disableSortBy: true,
    maxWidth: 100,
  },
  {
    id: 'mode',
    Cell: UpperCapCell,
    Header: 'Upper cap',
    accessor: 'mode',
    disableSortBy: true,
    maxWidth: 100,
  },
];
