import { useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { IColumn } from 'react-ui-kit-exante';

import {
  useGetLimitsTreeQuery,
  useLazySearchLimitsInstrumentsQuery,
} from '~/api';
import { MAX_REQUEST_LIMIT } from '~/constants';
import { TreeStructure } from '~/shared/components';
import {
  resetTable,
  selectExpandedRows,
  selectHasChangedLimits,
  selectLimitsTree,
} from '~/store/limits';
import { LimitsTree } from '~/types/limits';

import {
  useColumns,
  useHandleCellClick,
  useRefreshData,
  useSave,
  useTabAccount,
  useTabGroup,
} from './hooks';

export const Limits = () => {
  useGetLimitsTreeQuery();

  const limitsTree = useSelector(selectLimitsTree);
  const expandedRows = useSelector(selectExpandedRows);
  const hasChangedLimits = useSelector(selectHasChangedLimits);
  const [searchInstruments] = useLazySearchLimitsInstrumentsQuery();

  const searchValue = useRef<string>('');

  const columns: IColumn<LimitsTree>[] = useColumns();

  const dispatch = useDispatch();

  const handleCellClick = useHandleCellClick();

  const saveHandle = useSave();

  const filterChangeHandle = useCallback(() => {
    dispatch(resetTable());
  }, [dispatch]);

  const onSearch = useCallback(
    async (value: string, callback?: unknown) => {
      searchValue.current = value;

      if (value.length >= 3) {
        await searchInstruments({
          search: value,
          limit: MAX_REQUEST_LIMIT,
          skip: 0,
        });
      } else {
        dispatch(resetTable());
      }

      if (typeof callback === 'function') {
        dispatch(callback());
      }
    },
    [searchInstruments, dispatch],
  );

  const tabAccount = useTabAccount();
  const tabGroup = useTabGroup();

  const { onRefresh, isRefreshLoading } = useRefreshData({
    searchValue,
    onSearch,
  });

  return (
    <TreeStructure<LimitsTree>
      columns={columns}
      expandedRows={expandedRows}
      handleCellClick={handleCellClick}
      hasChangedData={hasChangedLimits}
      isLoading={isRefreshLoading}
      onFilterChange={filterChangeHandle}
      onRefresh={onRefresh}
      onSave={saveHandle}
      onSearch={onSearch}
      tabAccount={tabAccount}
      tabGroup={tabGroup}
      tableData={limitsTree}
      title="Limits"
    />
  );
};
