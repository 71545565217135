import { get, set } from 'lodash';

import { REGEX_DOUBLE_COLON } from '~/constants';
import { RowType } from '~/types/common';
import { ILimitsResponseTree, LimitsTree } from '~/types/limits';

import { ConvertedLimitsFromFlatToStructure } from '../types';

export function convertLimitsStructureFromFlatToTree(
  items: ILimitsResponseTree[],
): ConvertedLimitsFromFlatToStructure {
  const tree: LimitsTree[] = [];
  const pathByPositionInTree: Record<string, string> = {};

  const LIMITS = {
    mode: null,
    negativeLim: null,
    positiveLim: null,
    override: null,
  };

  items.forEach((item) => {
    const [parent, current] = item.path.split(REGEX_DOUBLE_COLON);

    // decided to hide the forex node on the front
    if (parent !== 'FOREX') {
      if (!current) {
        tree.push({
          ...item,
          ...LIMITS,
          rowType: RowType.Node,
          subRows: [],
        });
        pathByPositionInTree[item.path] = String(tree.length - 1);
      } else {
        const subRows: LimitsTree[] = get(
          tree,
          `${pathByPositionInTree[parent]}.subRows`,
          [],
        );
        subRows.push({
          ...item,
          ...LIMITS,
          rowType: RowType.Node,
          subRows: [],
        });
        pathByPositionInTree[item.path] = `${
          pathByPositionInTree[parent]
        }.subRows.${subRows.length - 1}`;
        set(tree, `${pathByPositionInTree[parent]}.subRows`, subRows);
      }
    }
  });

  return { tree, pathByPositionInTree };
}
