import { debounce } from 'lodash';
import { useState, useCallback, useMemo } from 'react';
import { SearchInput } from 'react-ui-kit-exante';

import { DEFAULT_DEBOUNCE_TIMEOUT } from '~/constants';

interface SearchProps {
  onSearch: (value: string) => void;
}

export const Search = ({ onSearch }: SearchProps) => {
  const [searchValue, setSearchValue] = useState('');
  const inputProps = { value: searchValue, style: { width: '400px' } };

  const handleSearch = useCallback(
    async (value: string) => {
      const search = value.trim();

      await onSearch(search);
    },
    [onSearch],
  );

  const debouncedHandleChangeInput = useMemo(
    () => debounce(handleSearch, DEFAULT_DEBOUNCE_TIMEOUT),
    [handleSearch],
  );

  const handleChange = useCallback(
    (value: string) => {
      setSearchValue(value);

      debouncedHandleChangeInput(value);
    },
    [setSearchValue, debouncedHandleChangeInput],
  );

  return (
    <SearchInput
      onChange={handleChange}
      size="medium"
      inputProps={inputProps}
    />
  );
};
