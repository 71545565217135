import { isString } from 'lodash';
import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { UseColumnOrderInstanceProps } from 'react-table';

import { IColumn, TableProps, TColumnResetType } from '../../types';

import {
  getDefaultColumnKeys,
  getHiddenColumns,
  getKeysByColumns,
  getPredefinedColumnKeys,
  getRequiredColumnKeys,
} from './utils';

interface IUseDisplayedColumnsKeysProps<Data extends object>
  extends UseColumnOrderInstanceProps<Data> {
  resetResizing(): void;
  displayedColumnKeys?: string[];
  tableId: string;
  tableLSWidthKey: string;
  setHiddenColumns(columns: string[]): void;
  columns: IColumn<Data>[];
  isNotSaveVisibleColumns?: boolean;
  onVisibleColumnsChange?: TableProps<Data>['onVisibleColumnsChange'];
}

export function useColumnsKeys<Data extends object>({
  resetResizing,
  displayedColumnKeys,
  tableId,
  tableLSWidthKey,
  setHiddenColumns,
  columns,
  isNotSaveVisibleColumns,
  onVisibleColumnsChange,
}: IUseDisplayedColumnsKeysProps<Data>) {
  const tableLSColumnsKey = `${tableId}-columns`;
  const lsColumnsData = localStorage.getItem(tableLSColumnsKey) ?? '';
  const requiredColumnKeys = useMemo(
    () => getRequiredColumnKeys(columns).filter((key) => isString(key)),
    [columns],
  );
  const defaultColumnKeys = useMemo(
    () =>
      getDefaultColumnKeys(columns, requiredColumnKeys, displayedColumnKeys),
    [columns, displayedColumnKeys, requiredColumnKeys],
  );
  const predefinedColumnKeys = useMemo(
    () =>
      getPredefinedColumnKeys({
        lsColumnsData,
        defaultColumnKeys,
        columns,
        requiredColumnKeys,
        isNotSaveVisibleColumns,
      }),
    [lsColumnsData, defaultColumnKeys, columns, requiredColumnKeys],
  );

  const [visibleColumnKeys, setVisibleColumnKeys] = useState<
    (string | undefined)[]
  >(() => predefinedColumnKeys);

  const hiddenColumns = useMemo(
    () => getHiddenColumns(columns, visibleColumnKeys),
    [visibleColumnKeys, columns],
  );

  const handleVisibleColumnKeysChange = useCallback(
    (key: string, checked: boolean) => {
      setVisibleColumnKeys((prev) => {
        const newKeys = checked
          ? [...prev, key]
          : prev.filter((column) => column !== key);

        if (onVisibleColumnsChange) {
          onVisibleColumnsChange(
            newKeys.filter((item): item is string => !!item),
          );
        }
        localStorage.setItem(tableLSColumnsKey, JSON.stringify(newKeys));

        return newKeys;
      });
    },
    [tableLSColumnsKey],
  );

  const resetColumnKeys = useCallback(
    (type: TColumnResetType = 'default') => {
      localStorage.removeItem(tableLSWidthKey);
      resetResizing();

      let columnKeys = getKeysByColumns(columns);

      if (type === 'none') {
        columnKeys = [];
      }

      if (type === 'default') {
        if (defaultColumnKeys?.length) {
          columnKeys = defaultColumnKeys;
        }
      }

      localStorage.setItem(tableLSColumnsKey, JSON.stringify(columnKeys));
      setVisibleColumnKeys(columnKeys);
      if (onVisibleColumnsChange) {
        onVisibleColumnsChange(
          columnKeys.filter((item): item is string => !!item),
        );
      }
    },
    [
      columns,
      defaultColumnKeys,
      resetResizing,
      tableLSColumnsKey,
      tableLSWidthKey,
    ],
  );

  useLayoutEffect(() => {
    setVisibleColumnKeys(predefinedColumnKeys);
  }, [predefinedColumnKeys]);

  useLayoutEffect(() => {
    setHiddenColumns(hiddenColumns);
  }, [hiddenColumns, setHiddenColumns]);

  return {
    resetColumnKeys,
    handleVisibleColumnKeysChange,
    visibleColumnKeys: visibleColumnKeys.filter(
      (item) => item !== undefined,
    ) as string[],
  };
}
