import { useCallback, useEffect, useMemo, useState, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { IconButton, Notification } from 'react-ui-kit-exante';

import { setGroupId, selectGroup } from '~/store/treeStructure';
import { IOption } from '~/types/form';
import { TabGroup } from '~/types/treeStructure';

import { AddGroupModal } from '../AddGroupModal';
import { DeleteGroupModal } from '../DeleteGroupModal';
import { EditGroupModal } from '../EditGroupModal';

import {
  IconDeleteStyled,
  SelectStyled,
  WrapperStyled,
} from './GroupsFilter.styled';

interface GroupsFilterProps {
  tabGroup: TabGroup;
  onFilterChange: () => void;
}

export const GroupsFilter = ({
  tabGroup,
  onFilterChange,
}: GroupsFilterProps) => {
  const [options, setOptions] = useState<IOption[]>([]);

  const { addGroup, editGroup, getOptions, deleteGroup } = tabGroup;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const paramsGroupId = searchParams.get('groupId');

  const dispatch = useDispatch();
  const groupId = useSelector(selectGroup);

  const [addGroupModalIsOpen, toggleAddGroupModalIsOpen] = useReducer(
    (v) => !v,
    false,
  );

  const [editGroupModalIsOpen, toggleEditGroupModalIsOpen] = useReducer(
    (v) => !v,
    false,
  );

  const [deleteGroupModalIsOpen, toggleDeleteGroupModalIsOpen] = useReducer(
    (v) => !v,
    false,
  );

  const groupNames = useMemo(
    () => new Set(options.map(({ label }) => label)),
    [options],
  );

  const onChangeHandler = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setGroupId(event.target.value));

      navigate(`?layer=groups&groupId=${event.target.value}`);

      onFilterChange();
    },
    [navigate, onFilterChange, dispatch],
  );

  const onAddGroupHandler = useCallback(
    async (newGroupName: string) => {
      const newGroupId = await addGroup(newGroupName);

      if (!newGroupId) {
        Notification.error({
          title: 'Error adding a group',
        });

        return;
      }

      getOptions().then((newOptions) => {
        setOptions(newOptions);

        dispatch(setGroupId(newGroupId));
      });

      Notification.success({
        title: 'Group was successfully added',
      });

      toggleAddGroupModalIsOpen();
    },
    [toggleAddGroupModalIsOpen, addGroup, dispatch, getOptions],
  );

  const onEditGroupHandler = useCallback(
    async (newGroupName: string, id: number) => {
      const result = await editGroup(newGroupName, id);

      if (!result) {
        Notification.error({
          title: 'Error changing a group',
        });
      }

      setOptions((oldOptions) =>
        oldOptions.map((oldOption) => {
          if (Number(oldOption.value) === id) {
            return { value: oldOption.value, label: newGroupName };
          }

          return oldOption;
        }),
      );

      Notification.success({
        title: 'Group name was successfully changed',
      });

      toggleEditGroupModalIsOpen();
    },
    [editGroup],
  );

  const onDeleteGroupHandler = useCallback(async () => {
    const errorMessage = await deleteGroup();

    if (errorMessage) {
      Notification.error({
        title: 'Error deleting a group',
        description: errorMessage,
      });

      return;
    }

    getOptions().then((newOptions) => {
      setOptions(newOptions);

      dispatch(setGroupId(newOptions[0].value));
    });

    Notification.success({
      title: 'Group was successfully deleted',
    });

    toggleDeleteGroupModalIsOpen();
  }, [getOptions, dispatch, toggleDeleteGroupModalIsOpen, deleteGroup]);

  useEffect(() => {
    getOptions().then((result) => {
      const hasParamsGroupId = result.find(
        ({ value }) => String(value) === paramsGroupId,
      );

      dispatch(setGroupId(hasParamsGroupId ? paramsGroupId : result[0].value));

      setOptions(result);

      if (!hasParamsGroupId) {
        navigate(`?layer=groups&groupId=${result[0].value}`);

        onFilterChange();
      }
    });
  }, []);

  return options.length ? (
    <WrapperStyled>
      <SelectStyled
        label="Group"
        onChange={onChangeHandler}
        options={options}
        value={String(groupId)}
      />

      <IconButton
        iconColor="action"
        iconName="EditIcon"
        onClick={toggleEditGroupModalIsOpen}
      />

      <IconDeleteStyled
        iconColor="action"
        iconName="DeleteIcon"
        onClick={toggleDeleteGroupModalIsOpen}
      />

      <IconButton
        iconColor="action"
        iconName="AddIcon"
        label="Add Group"
        onClick={toggleAddGroupModalIsOpen}
      />

      {addGroupModalIsOpen && (
        <AddGroupModal
          existGroupNames={groupNames}
          onClose={toggleAddGroupModalIsOpen}
          onSave={onAddGroupHandler}
        />
      )}

      {editGroupModalIsOpen && groupId && (
        <EditGroupModal
          existGroupNames={groupNames}
          groupId={groupId}
          groupName={
            options.find(({ value }) => Number(value) === groupId)?.label || ''
          }
          onClose={toggleEditGroupModalIsOpen}
          onSave={onEditGroupHandler}
        />
      )}

      {deleteGroupModalIsOpen && (
        <DeleteGroupModal
          onClose={toggleDeleteGroupModalIsOpen}
          onDelete={onDeleteGroupHandler}
        />
      )}
    </WrapperStyled>
  ) : null;
};
