import { useMemo } from 'react';
import { IColumn, OnSaveEditableRow, Table } from 'react-ui-kit-exante';

import { TCompletedInterestRate } from '~/api/interestRates/types';
import { RefreshButton } from '~/shared/components';
import { getTableId } from '~/shared/utils';
import { IInterestRate } from '~/types/interestRates';

import { defaultSortBy } from './constants';

interface IInterestRatesTableProps {
  loading: boolean;
  data: TCompletedInterestRate[];
  onUpdate: OnSaveEditableRow<TCompletedInterestRate>;
  columns: IColumn<IInterestRate>;
  filters: Partial<Record<keyof IInterestRate, unknown>>;
  removeAllFilters: () => void;
  onRefresh: () => void;
}

export const InterestRatesTable = ({
  loading,
  columns,
  data,
  onUpdate,
  filters,
  removeAllFilters,
  onRefresh,
}: IInterestRatesTableProps) => {
  const rowActions = useMemo(
    () => ({
      show: true,
      onSave: onUpdate,
      onCancel: () => Promise.resolve(),
    }),
    [onUpdate],
  );

  return (
    <Table
      columns={columns}
      data={data}
      defaultSortBy={defaultSortBy}
      filteringProps={{
        filters,
        removeAllFilters,
      }}
      filtersExpanded
      hasFilters
      hasPagination
      isFlexLayout
      isLoading={loading}
      rowActions={rowActions}
      saveViewParamsAfterLeave
      saveColumnOrder
      showTableInfo
      tableId={getTableId('InterestRates')}
      handleCellDataEdit={() => {}}
      additionalActions={[
        {
          component: <RefreshButton onRefresh={onRefresh} type="button" />,
        },
      ]}
      copyPasteMode
    />
  );
};
